export function ArrToPagenation(arr = [], pagesize = 20) {
    let totalPages = Math.ceil(arr.length / pagesize) // 总页数
    let mpageSize = pagesize; // 每页显示的条数
    let total = arr.length //总数据量
    let position = pagesize; // 在数组的的位置，取数据的结束位置
    let pageData = {} // 存储数据的对象
    for (let k = 0; k < totalPages; k++) {
        if (k === 0) {
            pageData[k] = arr.slice(0, position)
        } else {
            let data = (position + mpageSize >= arr.length) ? arr.slice(position, arr.length) : arr.slice(position, position + mpageSize)
            pageData[k] = data
            position = position + mpageSize
        }
    }
    return {
        totalPages: totalPages,
        total: total,
        data: pageData
    }
}