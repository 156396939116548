<template>
  <span v-if="isClaim">
    <el-tag :size="size">认领</el-tag>
  </span>
</template>

<script>
import { GetCheckClientClaimed } from "../api";
export default {
  props: {
    clientId: {
      type: [Number, String],
      default: null,
    },
    size:{
      type: String,
      default:''
    },
  },
  data() {
    return {
      isClaim: false,
    };
  },
  watch: {
    clientId: {
      handler() {
        this.handleCheckClaim();
      },
      immediate: true,
    },
  },
  methods: {
    handleCheckClaim() {
      if (this.clientId !== "" && this.clientId !== null) {
        GetCheckClientClaimed(this.clientId)
          .then((res) => {
            this.isClaim = res.data;
          })
          .catch(() => {
            this.isClaim = false;
          });
      } else {
        this.isClaim = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>