<template>
  <div class="import-wrapper">
    <upload-excel-component
      :on-success="handleSuccess"
      :before-upload="beforeUpload"
      ><a
        href="/static/clienImport.xlsx"
        download="客户导入模板.xlsx"
        class="text-nav mt-l"
        style="font-size: 14px"
        >下载导入模板</a
      ></upload-excel-component
    >
    <p>匹配不到的字段请重新选择</p>
    <el-table
      :data="tableData"
      border
      highlight-current-row
      style="width: 100%; margin-top: 20px"
    >
      <el-table-column
        v-if="tableData.length > 0"
        type="index"
        fixed="left"
        width="50"
      >
      </el-table-column>
      <el-table-column
        v-for="item of tableHeader"
        :key="item.propId"
        :prop="item.propId"
        width="200"
        :label="item.propName"
      >
        <template slot-scope="scope">
          <el-input
            v-if="item.propId === 'name' && scope.row.canEdit"
            v-model="scope.row.name"
            style="width: 100%"
            placeholder="请填写客户名称"
            clearable
            :disabled="submitting"
          ></el-input>

          <el-input
            v-else-if="item.propId === 'mainContactName' && scope.row.canEdit"
            v-model="scope.row.mainContactName"
            style="width: 100%"
            placeholder="请填写联系人姓名"
            clearable
            :disabled="submitting"
          ></el-input>
          <el-input
            v-else-if="item.propId === 'mainContactPhone' && scope.row.canEdit"
            v-model="scope.row.mainContactPhone"
            style="width: 100%"
            placeholder="请填写联系人电话"
            clearable
            :disabled="submitting"
          ></el-input>

          <el-input
            v-else-if="item.propId === 'brand' && scope.row.canEdit"
            v-model="scope.row.brand"
            style="width: 100%"
            placeholder="请填写客户品牌"
            clearable
            :disabled="submitting"
          ></el-input>
          <el-input
            v-else-if="item.propId === 'country' && scope.row.canEdit"
            v-model="scope.row.country"
            style="width: 100%"
            placeholder="请填写国家"
            clearable
          ></el-input>
          <el-input
            v-else-if="item.propId === 'province' && scope.row.canEdit"
            v-model="scope.row.province"
            style="width: 100%"
            placeholder="请填写省份"
            :disabled="submitting"
            clearable
          ></el-input>
          <el-input
            v-else-if="item.propId === 'city' && scope.row.canEdit"
            v-model="scope.row.city"
            style="width: 100%"
            placeholder="请填写城市"
            :disabled="submitting"
            clearable
          ></el-input>
          <el-input
            v-else-if="item.propId === 'region' && scope.row.canEdit"
            v-model="scope.row.region"
            style="width: 100%"
            placeholder="请填写地区"
            :disabled="submitting"
            clearable
          ></el-input>
          <el-select
            v-else-if="item.propId === 'typeId'"
            style="width: 100%"
            v-model="scope.row.typeId"
            placeholder="请选择类型"
            clearable
            filterable
            :disabled="submitting || !scope.row.canEdit"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.id + item.name"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>

          <el-select
            v-else-if="item.propId === 'followUpStatusId'"
            style="width: 100%"
            v-model="scope.row.followUpStatusId"
            placeholder="请选择跟进状态"
            clearable
            filterable
            :disabled="submitting || !scope.row.canEdit"
          >
            <el-option
              v-for="item in followUpStatusOptions"
              :key="item.id + item.name"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-else-if="item.propId === 'ownerId'"
            style="width: 100%"
            v-model="scope.row.ownerId"
            placeholder="请选择负责人"
            clearable
            filterable
            :disabled="submitting || !scope.row.canEdit"
          >
            <el-option
              v-for="item in ownerOptions"
              :key="item.id + item.name"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-else-if="item.propId === 'sourceId'"
            style="width: 100%"
            v-model="scope.row.sourceId"
            placeholder="请选择途径"
            clearable
            filterable
            :disabled="submitting || !scope.row.canEdit"
          >
            <el-option
              v-for="item in sourceOptions"
              :key="item.id + item.name"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>

          <el-select
            v-else-if="item.propId === 'stageId'"
            style="width: 100%"
            v-model="scope.row.stageId"
            placeholder="请选择阶段"
            filterable
            clearable
            :disabled="submitting || !scope.row.canEdit"
          >
            <el-option
              v-for="item in stageOptions"
              :key="item.id + item.name"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <span v-else>{{ scope.row[item.propId] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableData.length > 0"
        prop="control"
        align="center"
        label="提交状态"
        width="100"
        fixed="right"
      >
        <template slot-scope="scope">
          <span
            v-if="
              !scope.row.submitting &&
              scope.row.statusCode !== 200 &&
              scope.row.statusCode !== 400
            "
            >{{ scope.row.statusText }}</span
          >
          <span
            v-if="!scope.row.submitting && scope.row.statusCode === 200"
            class="text-success"
            >{{
              scope.row.submitting ? "提交中..." : scope.row.statusText
            }}</span
          >
          <el-popover
            v-if="scope.row.statusCode === 400 && !scope.row.submitting"
            placement="left"
            title="失败原因:"
            width="200"
            trigger="click"
            style="height: 300px; overflow: auto"
            :content="scope.row.statusText"
          >
            <span slot="reference" class="text-danger"
              >{{ scope.row.submitting ? "提交中..." : "提交失败" }}
              <i class="el-icon-info"></i>
            </span>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableData.length > 0"
        prop="control"
        align="center"
        label="操作"
        width="150"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            class="control-btn"
            size="small"
            v-if="scope.row.statusCode && scope.row.statusCode === 400"
            @click="rePost(scope.row, scope.$index)"
            >重试</el-button
          >
          <el-button
            type="text"
            class="control-btn text-warning"
            size="small"
            :disabled="submitting"
            @click="handleToggleEdit(scope.row, scope.$index)"
            >{{ scope.row.canEdit ? "修改完成" : "点击修改" }}</el-button
          >

          <el-button
            type="text"
            class="control-btn"
            size="small"
            :disabled="submitting"
            @click="deleteRow(scope.row, scope.$index)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="paganation-wrap">
      <el-pagination
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :current-page="page + 1"
        :page-size="size"
        :total="total"
        :disabled="submitting"
      >
      </el-pagination>
    </div>
    <div :style="{ textAlign: 'right', padding: '10px 0' }" class="clearfix">
      <el-button disabled
        >已发送耗时{{ (countTime / 1000 / 60).toFixed(2) }} 分钟</el-button
      >
      <el-button disabled>剩下{{ _currentNumer }} 条数据</el-button>
      <el-button type="primary" @click="postAllFormItem" :loading="submitting"
        >提交新增</el-button
      >
      <el-button @click="cancelPost" :disabled="submitting">关 闭</el-button>
    </div>
  </div>
</template>

<script>
import UploadExcelComponent from "@/components/UploadExcel/index.vue";
import formSelection from "../mixins/clientForm.js";
import { guid2 } from "@/utils/common";
import { isEmprty, isPhoneNumber } from "@/utils/validate";
import { ArrToPagenation } from "@/utils/pagenation";
import { PostClient } from "../api";
export default {
  components: { UploadExcelComponent },
  mixins: [formSelection],
  data() {
    return {
      submitting: false,
      tableData: [],
      tableHeader: [],
      formData: [],
      pageData: {},
      page: 0,
      size: 10,
      totalPages: 0,
      total: 0,
      curretNumber: 0,
      countTime: 0,
      timer: null,
    };
  },
  created() {
    this.initFormSelection();
  },
  computed: {
    _currentNumer() {
      let dataLentgh = this.formData.length;
      return this.curretNumber <= 0
        ? dataLentgh
        : dataLentgh - this.curretNumber;
    },
  },
  methods: {
    isEmprty,
    handleCurrentChange(val) {
      this.page = val - 1;
      this.tableData = this.pageData[this.page];
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: "上传的文件不能大于1M",
        type: "warning",
      });
      return false;
    },
    handleSuccess({ results, header }) {
      let formatData = [];
      let tempTableHeader = [];
      results.forEach((item) => {
        let tempObj = {
          country: "中国",
          province: "",
          brand: "",
          region: "",
          city: "",
          name: "",
          typeId: null,
          followUpStatusId: null,
          mainContactName: "",
          mainContactPhone: "",
          ownerId: null,
          stageId: null,
          sourceId: null,
          canEdit: false,
          guid: guid2(),
          submitting: false,
          statusCode: null,
          statusText: "待提交",
        };

        for (const key in item) {
          tempObj[this.getObjKey(key)] = this.getObjValue(
            this.getObjKey(key),
            item[key]
          );
        }
        formatData.push(tempObj);
      });
      tempTableHeader = header.map((item) => {
        return {
          propName: item,
          propId: this.getObjKey(item),
        };
      });
      this.tableHeader = tempTableHeader;
      this.formData = formatData;
      this.initPageData(this.formData);
    },
    // 读取出来的excel的键名都是中文的，所以需要把键名换成对应的字段名
    getObjKey(keyname = "") {
      switch (keyname) {
        case "客户类型":
          return "typeId";
        case "客户名称":
          return "name";
        case "联系人姓名":
          return "mainContactName";
        case "联系人电话":
          return "mainContactPhone";
        case "国家":
          return "country";
        case "省份":
          return "province";
        case "城市":
          return "city";
        case "地区":
          return "region";
        case "客户品牌":
          return "brand";
        case "跟进状态":
          return "followUpStatusId";
        case "负责人":
          return "ownerId";
        case "客户来源":
          return "sourceId";
        case "客户阶段":
          return "stageId";
        default:
          break;
      }
    },
    // 拿回来的数据都是文本状态的，有些字段需要匹配选单中的对应的id才能回传
    getObjValue(keyname = "", value = "") {
      let target = null;
      switch (keyname) {
        case "typeId":
          target = this.typeOptions.find((item) => item.name == value);
          return target ? target.id : null;
        case "name":
          return value;
        case "mainContactName":
          return value;
        case "mainContactPhone":
          return value;
        case "country":
          return value;
        case "province":
          return value;
        case "city":
          return value;
        case "region":
          return value;
        case "brand":
          return value;
        case "followUpStatusId":
          target = this.followUpStatusOptions.find(
            (item) => item.name == value
          );
          return target ? target.id : null;
        case "ownerId":
          target = this.ownerOptions.find((item) => item.name == value);
          return target ? target.id : null;
        case "sourceId":
          target = this.sourceOptions.find((item) => item.name == value);
          return target ? target.id : null;
        case "stageId":
          target = this.stageOptions.find((item) => item.name == value);
          return target ? target.id : null;

        default:
          break;
      }
    },
    // 验证每一项表单
    validateFormItem(item, index) {
      if (isEmprty(item.typeId)) {
        this.$message.error(`请选择第${index + 1}行数据的类型`);
        return false;
      }
      if (isEmprty(item.name)) {
        this.$message.error(`请填写第${index + 1}行数据的名字`);
        return false;
      }
      // if (isEmprty(item.brand)) {
      //   this.$message.error(`请填写第${index + 1}行数据的客户品牌`);
      //   return false;
      // }
      if (isEmprty(item.typeId)) {
        this.$message.error(`请填写第${index + 1}行数据的客户类型`);
        return false;
      }
      if (isEmprty(item.mainContactName)) {
        this.$message.error(`请填写第${index + 1}行数据的联系人名称`);
        return false;
      }
      if (!isPhoneNumber(item.mainContactPhone)) {
        this.$message.error(
          `第${
            index + 1
          }行数据的联系人电话有误，请填写138xxxx8000或者07xx-77xxx88格式的电话号码`
        );
        return false;
      }
      if (isEmprty(item.followUpStatusId)) {
        this.$message.error(`请选择第${index + 1}行数据的跟进状态`);
        return false;
      }
      if (isEmprty(item.ownerId)) {
        this.$message.error(`请选择第${index + 1}行数据的负责人`);
        return false;
      }
      if (isEmprty(item.sourceId)) {
        this.$message.error(`请选择第${index + 1}行数据的客户来源`);
        return false;
      }
      if (isEmprty(item.stageId)) {
        this.$message.error(`请选择第${index + 1}行数据的客户阶段`);
        return false;
      }
      if (isEmprty(item.country)) {
        this.$message.error(`第${index + 1}行数据的国家不能为空`);
        return false;
      }
      if (isEmprty(item.province) || isEmprty(item.city)) {
        this.$message.error(
          `第${
            index + 1
          }行数据的省份和城市不能为空，并且格式只能为【佛山】 ，不能填 【佛山市】`
        );
        return false;
      }
      return true;
    },
    postAllFormItem() {
      this.allSubmitDone = false;
      // 数据行验证
      if (this.formData.length <= 0) {
        this.$message.error("提交的数据不能为空!");
        return false;
      }
      //   数据行的字段验证
      let isPass = true;
      for (let i = 0; i < this.formData.length; i++) {
        if (!this.validateFormItem(this.formData[i], i)) {
          isPass = false;
          break;
        }
      }
      this.formData = this.formData.map((item) => {
        item.statusCode = null;
        item.submitting = false;
        item.statusText = "待提交";
        return item;
      });
      if (isPass) {
        this.submitting = true;
        //   提交
        this.curretNumber = 0;
        this.countTime = 0;
        this.timer = setInterval(() => {
          if (this.curretNumber > this.formData.length - 1) {
            clearInterval(this.timer);
            this.curretNumber = 0;
          }
          this.onPostItem(this.formData[this.curretNumber]);
          this.curretNumber = this.curretNumber + 1;
          this.countTime += 150;
        }, 150);
        this.checkSubmitStatus();
      }
    },
    formatSubmitItem(formData = {}) {
      return {
        address: {
          city: formData.city,
          country: formData.country,
          province: formData.province,
          region: formData.region,
          street: "",
        },
        brand: formData.brand,
        contacts: [],
        followUpStatusId: formData.followUpStatusId,
        mainContact: {
          address: null,
          email: "",
          name: formData.mainContactName,
          phoneNo: formData.mainContactPhone,
          position: "",
          qq: "",
          wx: "",
        },
        name: formData.name,
        ownerId: formData.ownerId,
        sourceId: formData.sourceId,
        stageId: formData.stageId,
        star: false,
        tagIds: [],
        typeId: formData.typeId,
        remark: "",
      };
    },
    onPostItem(params) {
      params.submitting = true;
      let formData = JSON.parse(JSON.stringify(params));
      delete formData.submitting;
      delete formData.statusCode;
      delete formData.statusText;
      // let timer = null;
      // timer = setTimeout(() => {
      //   clearTimeout(timer);
      //   if (this.curretNumber % 2 === 0) {
      //     params.submitting = false;
      //     params.statusCode = 200;
      //     params.statusText = "添加成功";
      //   } else {
      //     params.submitting = false;
      //     params.statusCode = 400;
      //     params.statusText = "我是自定义的api请求错误内容";
      //   }
      // });
      PostClient(this.formatSubmitItem(formData))
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            params.submitting = false;
            params.statusCode = 200;
            params.statusText = "添加成功";
          }
        })
        .catch((err) => {
          params.statusCode = 400;
          params.statusText = err.message;
          params.submitting = false;
        });
    },
    checkSubmitStatus() {
      let timer = null;
      timer = setInterval(() => {
        const allDone = this.formData.every((item) => item.statusCode !== null);
        this.allSubmitDone = allDone;
        // 检查是否全部提交，无论是否成功
        if (allDone) {
          this.submitting = false;
          // 检查是否全部提交成功
          const allSubmitSuccess = this.formData.every(
            (item) => item.statusCode === 200
          );
          if (allSubmitSuccess) {
            this.initTable();
            this.$message.success("全部提交成功!");
            this.$emit("success");
          } else {
            let successItems = this.formData.filter(
              (item) => item.statusCode === 200
            );
            let failItems = this.formData.filter(
              (item) => item.statusCode === 400
            );
            this.$message.warning(
              `提交成功${successItems.length} 条，提交失败${failItems.length} 条`
            );

            this.formData = failItems;
            this.initPageData(failItems);
            this.$emit("success");
          }

          clearInterval(timer);
        }
      }, 800);
    },
    rePost(row) {
      row.submitting = false;
      row.statusCode = "";
      row.statusText = "";
      this.onPostItem(row);
      this.checkSubmitStatus();
    },
    cancelPost() {
      // 如果没有数据，就直接关闭
      if (this.formData.length <= 0 && this.tableHeader.length <= 0) {
        this.$emit("cancel");
        return false;
      }
      this.$confirm(
        "关闭弹窗将会清空表单，如果已经确认提交状态，点击确定即可关闭弹窗",
        "是否确认关闭弹窗？",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.initTable();
          this.$emit("cancel");
        })
        .catch(() => {});
    },
    handleToggleEdit(row) {
      row.canEdit = !row.canEdit;
    },
    deleteRow(row) {
      this.formData = this.formData.filter((item) => item.guid !== row.guid);
      this.initPageData(this.formData);
    },
    initPageData(params = []) {
      let { totalPages, total, data } = ArrToPagenation(params, this.size);
      this.pageData = data;
      this.total = total;
      this.totalPages = totalPages;
      this.tableData = this.pageData[this.page];
    },
    initTable() {
      this.tableData = [];
      this.pageData = {};
      this.formData = [];
      this.curretNumber = 0;
      this.timer = null;
      this.countTime = 0;
      this.totalPages = 0;
      this.page = 0;
      this.total = 0;
      this.tableHeader = [];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>